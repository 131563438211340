import confetti from "canvas-confetti";

// @see https://www.kirilv.com/canvas-confetti/
export function fireConfetti() {
  fire(0.25, {
    spread: 26,
    startVelocity: 55,
  });
  fire(0.2, {
    spread: 60,
  });
  fire(0.35, {
    spread: 100,
    decay: 0.91,
    scalar: 0.8,
  });
  fire(0.1, {
    spread: 120,
    startVelocity: 25,
    decay: 0.92,
    scalar: 1.2,
  });
  fire(0.1, {
    spread: 120,
    startVelocity: 45,
  });
}

function fire(particleRatio, opts) {
  confetti({
    origin: { y: 0.7 },
    colors: ["#53257D", "#DD7380", "#f55924", "#dbc1ff"],
    particleCount: Math.floor(200 * particleRatio),
    disableForReducedMotion: true,
    ...opts,
  });
}
