import "./App.css";
import { flatten, groupBy, mapValues, reverse } from "lodash";
import { useCallback, useEffect, useMemo, useState } from "react";
import { DateTime } from "luxon";
import { fireConfetti } from "./confetti";
import { usePrefersReducedMotion } from "./useReducedMotion";

function App({
  flowers,
  initialTheme,
  initialFocusId,
  specialOccasions,
  specialOccasionToday,
}) {
  // Fire confetti on special days
  // NOTE: We fire on a timeout, to avoid janking on first render
  useEffect(() => {
    if (specialOccasionToday) {
      let timeout = setTimeout(() => {
        fireConfetti();
      }, 200);
      return () => clearTimeout(timeout);
    }
  }, [specialOccasionToday]);

  // Sync the initial focus id
  useEffect(() => {
    if (initialFocusId) {
      document.getElementById(initialFocusId)?.focus();
    }
  }, [initialFocusId]);

  return (
    <>
      <header>
        <h1>Daily Flower Garden</h1>
        {specialOccasionToday && <h2>{specialOccasionToday.label}</h2>}
        <ThemePicker initialTheme={initialTheme}></ThemePicker>
      </header>
      <main>
        <Grid specialOccasions={specialOccasions} flowers={flowers}></Grid>
      </main>
      <footer>
        <p>Made with 🌷 by Fotis Papadogeorgopoulos</p>
        <Vampire />
        <p>
          You can use this app offline, by installing it via your browser's "Add
          to Home Screen" functionality.
        </p>
      </footer>
    </>
  );
}

function ThemePicker({ initialTheme }) {
  const [currentTheme, setCurrentTheme] = useState(initialTheme);

  const pickTheme = (newTheme) => {
    setCurrentTheme(newTheme);
    document.documentElement.className = `theme-${newTheme}`;
    window.localStorage.setItem("theme", newTheme);
  };

  return (
    <div>
      <div className="theme-picker">
        {[
          { id: "og", label: "Original" },
          { id: "vanilla", label: "Vanilla" },
          { id: "sea-salt", label: "Sea Salt" },
          { id: "incognito", label: "Incognito" },
          { id: "darkest-night", label: "Darkest Night" },
        ].map(({ id, label }) => (
          <button
            key={id}
            className={`theme-picker-button theme-${id}`}
            onClick={() => pickTheme(id)}
            aria-label={label}
            aria-pressed={currentTheme === id}
          ></button>
        ))}
      </div>
    </div>
  );
}

function Vampire() {
  const [isVampireShown, setVampireShown] = useState(false);
  const toggleVampire = useCallback(
    () => setVampireShown(!isVampireShown),
    [isVampireShown]
  );

  return (
    <button
      className="vampire"
      onClick={toggleVampire}
      aria-pressed={isVampireShown}
    >
      🧛
    </button>
  );
}

function Grid({ flowers, specialOccasions }) {
  const flowersByMonth = useMemo(() => {
    // Group flowers by month
    // Also reverse, so that recent months and years come first
    return reverse(groupFlowersByMonthAndYear(flowers));
  }, [flowers]);

  return (
    <div className="Months">
      {flowersByMonth.map(({ year, month, flowers }) => (
        <div className="Month" key={`${year}-${month}`}>
          <h2>
            {month} {year}
          </h2>
          <ul className="Grid">
            {/* Reverse the days, so that recent days come first */}
            {reverse(flowers).map(({ dateTime, emoji }) => (
              <li
                key={dateTime.toISO()}
                id={`day-${dateTime.year}-${dateTime.month}-${dateTime.day}`}
                tabIndex={-1}
                className="Grid-Item"
              >
                {findOccasion(specialOccasions, dateTime) ? (
                  <SpecialDay
                    occasion={findOccasion(specialOccasions, dateTime)}
                  ></SpecialDay>
                ) : (
                  <RegularDay dateTime={dateTime} emoji={emoji}></RegularDay>
                )}
              </li>
            ))}
          </ul>
        </div>
      ))}
    </div>
  );
}

export function findOccasion(specialOccasions, dateTime) {
  return specialOccasions.find((occasion) =>
    dateTime.equals(occasion.dateTime)
  );
}

function SpecialDay({ occasion }) {
  const { dateTime, label, emoji } = occasion;
  const prefersReducedMotion = usePrefersReducedMotion();
  const [showCelebrationText, setShowText] = useState(false);

  const onLaunchConfetti = useCallback(
    (ev) => {
      ev.preventDefault();
      if (prefersReducedMotion) {
        setShowText(!showCelebrationText);
      } else {
        fireConfetti();
      }
    },
    [prefersReducedMotion, showCelebrationText]
  );

  return (
    <>
      <span className="emoji">
        {showCelebrationText ? "🎉🎉🎉" : emoji.map((emoji) => emoji)}
      </span>
      <div href={`#day-${dateTime.year}-${dateTime.month}-${dateTime.day}`}>
        <button
          className="Occasion-button confetti-cannon"
          onClick={onLaunchConfetti}
          // NOTE: null is intentional; if prefers reduced motion, this is a toggle button with true/false pressed state,
          // but otherwise it is a regular button (in which case, aria-pressed must be omitted)
          aria-pressed={prefersReducedMotion ? showCelebrationText : null}
        >
          <span className="Occasion-label">{label}</span>
        </button>
      </div>
    </>
  );
}

function RegularDay({ dateTime, emoji }) {
  return (
    <>
      <span className="emoji">{emoji.map((emoji) => emoji)}</span>
      <a href={`#day-${dateTime.year}-${dateTime.month}-${dateTime.day}`}>
        {dateTime.day}

        {dateTime.hasSame(DateTime.now(), "day") ? <span> (Today)</span> : null}
      </a>
    </>
  );
}

function groupFlowersByMonthAndYear(allFlowers) {
  // Group to shape {2022: {'May': FlowersWithDatetime[], 'June': FlowersWithDatetime[]}}
  const groupedByYear = groupBy(allFlowers, ({ dateTime }) => dateTime.year);
  const groupedByMonthAndYear = mapValues(groupedByYear, (yearFlowers) => {
    const groupedByMonth = groupBy(
      yearFlowers,
      ({ dateTime }) => dateTime.monthLong
    );
    return groupedByMonth;
  });

  // Convert to [{year: 2022, month: 'May', flowers: FlowersWithDateTime[]}]
  const final = flatten(
    Object.entries(groupedByMonthAndYear).map(([year, groupedByMonth]) => {
      return Object.entries(groupedByMonth).map(([month, flowers]) => {
        return {
          year,
          month,
          flowers,
        };
      });
    })
  );

  return final;
}

export default App;
