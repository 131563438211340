import React from "react";
import ReactDOM from "react-dom/client";
import arbit from "arbit";
import "./index.css";
import App, { findOccasion } from "./App";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import reportWebVitals from "./reportWebVitals";
import { generateFlowersUntil } from "./flowerGenerator";
import { DateTime } from "luxon";

// Constants
const SEED = "DAILYFLOWERGARDEN";

/**
 * @type {
 *  {
 *    id: string;
 *    from: DateTime;
 *    to?: DateTime;
 *    pool: Array<string>;
 *  }
 * }
 * NOTE: Changing a pool changes historical results, because the distribution changes!
 * To avoid that, we use versioning; we set a start time for a pool, and pull from it until the end time.
 * Then, we switch over to the next pool.
 * Ah, PRNGS are fun :)
 */
const Versioning = {
  // v1 was until September
  v1: {
    id: "v1",
    // The start time for the flower sequence is May 15, 2022
    from: DateTime.local(2022, 5, 15),
    pool: ["🌷", "🥀", "🌼", "🌻", "🌹", "🌸", "🌺", "💐"],
  },
  // Spooktober
  "v1-october": {
    id: "v1-october",
    from: DateTime.local(2022, 10, 1),
    pool: ["🌷", "🌼", "🌹", "🌸", "🌺", "🎃", "👻", "🍂", "🥧", "🧙‍♀️", "🧹"],
  },
  // Spooktober, but even more Autumn
  "v1-october-more-autumn": {
    id: "v1-october-more-autumn",
    from: DateTime.local(2022, 10, 4),
    pool: ["🌷", "🌺", "🎃", "👻", "🍂", "🥧", "🧙‍♀️", "🧹", "🐈‍⬛"],
  },
  "v2-after-october": {
    id: "v2-after-october",
    from: DateTime.local(2022, 11, 1),
    pool: ["🌷", "🌺", "🍂", "🥧", "🐈‍⬛", "🌹", "🌻", "💐"],
  },
};

// Initialise a PRNG with the seed; the PRNG has internal state, so be wary
const generator = arbit(SEED);

export const SPECIAL_OCCASIONS = [
  {
    id: "3-month",
    dateTime: DateTime.local(2022, 8, 13),
    label: "Happy 3 months!",
    emoji: ["💜", "💓", "💜"],
  },
  {
    id: "ruta-birthday-2022",
    dateTime: DateTime.local(2022, 9, 20),
    label: "Happy birthday!",
    emoji: ["🎉", "🎂", "🎉"],
  },
];

/**
 * Parse ?_overrideToday in the window location, as a DateTime, if specified
 */
function getOverrideFromWindow() {
  const params = new URLSearchParams(window.location.search);
  const asString = params.get("_overrideToday");

  if (!asString) {
    return;
  }

  const asDateTime = DateTime.fromISO(asString);
  console.log("Got override for date:", asDateTime);

  if (!asDateTime.isValid) {
    console.warn("Override is invalid:", asDateTime.invalidExplanation);
    return;
  }

  return asDateTime;
}

function init() {
  // If today (day of viewing) is a special occasion, let the app know
  const now = getOverrideFromWindow() ?? DateTime.now();
  console.log({ now });

  // TODO: Refactor this to just pass now to findOccasion, and let it figure out the format
  const nowOnlyDate = DateTime.local(now.year, now.month, now.day);
  const specialOccasionToday = findOccasion(SPECIAL_OCCASIONS, nowOnlyDate);

  //   Generate a sequence of flowers until today
  const flowers = generateFlowersUntil({
    generator,
    versions: Versioning,
    end: now,
  });
  console.log(flowers);

  const initialAnchorId = window.location.hash?.split("#")?.[1];

  const root = ReactDOM.createRoot(document.getElementById("root"));

  // Get the theme before painting to the screen
  const themeName = window.localStorage.getItem("theme");
  if (themeName) {
    document.documentElement.className = `theme-${themeName}`;
  }

  root.render(
    <React.StrictMode>
      <App
        specialOccasions={SPECIAL_OCCASIONS}
        specialOccasionToday={specialOccasionToday}
        flowers={flowers}
        initialFocusId={initialAnchorId}
        initialTheme={themeName ? themeName : "og"}
      />
    </React.StrictMode>
  );

  // If you want your app to work offline and load faster, you can change
  // unregister() to register() below. Note this comes with some pitfalls.
  // Learn more about service workers: https://cra.link/PWA
  serviceWorkerRegistration.register();

  // If you want to start measuring performance in your app, pass a function
  // to log results (for example: reportWebVitals(console.log))
  // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
  reportWebVitals();
}

init();
